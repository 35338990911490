import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { Navigation } from '@/components/Navigation';

const Index = lazy(() => import('@/pages/Index'));
const Auth = lazy(() => import('@/pages/Auth'));
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const Subscription = lazy(() => import('@/pages/Subscription'));
const SubscriptionSuccess = lazy(() => import('@/pages/SubscriptionSuccess'));
const Profile = lazy(() => import('@/pages/Profile'));
const Terms = lazy(() => import('@/pages/Terms'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const Debug = lazy(() => import('@/pages/Debug'));
const NotFound = lazy(() => import('@/pages/NotFound'));
const VerifyEmail = lazy(() => import('@/pages/VerifyEmail'));
const Workspace = lazy(() => import('@/pages/Workspace'));
const Project = lazy(() => import('@/pages/Project'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigation />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'auth',
        children: [
          {
            index: true,
            element: <Auth />,
          },
          {
            path: 'verify',
            element: <VerifyEmail />,
          },
          {
            path: 'callback',
            element: <VerifyEmail />,
          }
        ]
      },
      {
        path: 'dashboard',
        element: <PrivateRoute><Dashboard /></PrivateRoute>,
      },
      {
        path: 'workspace',
        element: <PrivateRoute><Workspace /></PrivateRoute>,
      },
      {
        path: 'workspace/project/:id',
        element: <PrivateRoute><Project /></PrivateRoute>,
      },
      {
        path: 'subscription',
        element: <PrivateRoute><Subscription /></PrivateRoute>,
      },
      {
        path: 'subscription/success',
        element: <PrivateRoute><SubscriptionSuccess /></PrivateRoute>,
      },
      {
        path: 'profile',
        element: <PrivateRoute><Profile /></PrivateRoute>,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'debug',
        element: <Debug />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]); 