import { createClient } from '@supabase/supabase-js';
import type { Database } from '@/integrations/supabase/types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Создаем единственный экземпляр клиента
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    storageKey: 'texty-me-storage-key', // Уникальный ключ для хранения
    autoRefreshToken: true, // Автоматическое обновление токена
    detectSessionInUrl: true // Определение сессии в URL после OAuth
  }
}); 