import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { Globe2, Sparkles, PenTool, History, LogOut, LayoutDashboard } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { ThemeToggle } from "@/components/ThemeToggle";
import { cn } from "@/lib/utils";

interface LandingHeaderProps {
  demoGenerationsLeft?: number;
  currentLanguage?: string;
}

const Logo = () => (
  <div className="flex items-center">
    <span className="font-['Days_One'] text-xl tracking-tight text-[#1D3557]">texty</span>
    <span className="text-[#2ECC71] mx-0.5 font-['Days_One'] text-xl tracking-tight">|</span>
    <span className="font-['Days_One'] text-xl tracking-tight text-[#2ECC71]">me</span>
  </div>
);

export default function LandingHeader({ demoGenerationsLeft, currentLanguage = "English" }: LandingHeaderProps) {
  const { user, signOut } = useAuth();

  // Header for non-authenticated users
  if (!user) {
    return (
      <nav className="border-b border-[#1D3557]/10 bg-white/70 backdrop-blur-md sticky top-0 z-50 transition-all duration-200">
        <div className="container mx-auto px-4 py-3">
          <div className="flex justify-between items-center">
            <Link to="/" className="hover:opacity-90 transition-opacity">
              <Logo />
            </Link>

            <div className="flex items-center gap-4">
              {demoGenerationsLeft !== undefined && (
                <div className="hidden sm:flex items-center gap-2 text-sm font-['Inter']">
                  <Sparkles className="h-4 w-4 text-[#2ECC71]" />
                  <span className="text-[#1D3557]">
                    Generations left: <Badge variant="secondary" className="bg-[#2ECC71]/10 text-[#2ECC71] hover:bg-[#2ECC71]/20 font-medium">{demoGenerationsLeft}</Badge>
                  </span>
                </div>
              )}
              
              <Link to="/auth">
                <Button variant="ghost" className="hidden sm:inline-flex text-[#1D3557] hover:text-[#1D3557]/80 hover:bg-[#1D3557]/5 font-['Inter'] font-medium">
                  Sign In
                </Button>
              </Link>
              <Link to="/auth">
                <Button className="bg-[#2ECC71] hover:bg-[#2ECC71]/90 text-white font-['Inter'] font-bold">
                  Try for Free
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  // Header for authenticated users
  return (
    <nav className="border-b border-[#1D3557]/10 bg-white/70 backdrop-blur-md sticky top-0 z-50 transition-all duration-200">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="/" className="hover:opacity-90 transition-opacity">
            <Logo />
          </Link>

          <div className="flex items-center gap-4">
            <Link to="/dashboard">
              <Button variant="ghost" className="gap-2 text-[#1D3557] hover:text-[#1D3557]/80 hover:bg-[#1D3557]/5 font-['Inter'] font-medium">
                <LayoutDashboard className="h-4 w-4" />
                <span className="hidden sm:inline">Dashboard</span>
              </Button>
            </Link>

            <ThemeToggle />

            <Button 
              variant="ghost" 
              size="icon" 
              onClick={signOut} 
              className="text-[#1D3557] hover:text-[#1D3557]/80 hover:bg-[#1D3557]/5"
            >
              <LogOut className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
} 