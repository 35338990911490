export const theme = {
  colors: {
    primary: {
      DEFAULT: '#2ECC71',
      hover: 'rgb(46 204 113 / 0.9)',
      light: 'rgb(46 204 113 / 0.1)',
      dark: '#27AE60'
    },
    secondary: {
      DEFAULT: '#1D3557',
      hover: 'rgb(29 53 87 / 0.9)',
      light: 'rgb(29 53 87 / 0.05)',
      text: 'rgb(29 53 87 / 0.8)'
    },
    accent: {
      DEFAULT: '#4F46E5',
      hover: 'rgb(79 70 229 / 0.9)',
      light: 'rgb(79 70 229 / 0.1)'
    },
    background: {
      DEFAULT: '#FFFFFF',
      gradient: 'from-[#1D3557]/5 via-white to-[#1D3557]/5'
    },
    text: {
      primary: '#1D3557',
      secondary: 'rgb(29 53 87 / 0.8)',
      muted: 'rgb(29 53 87 / 0.6)'
    },
    border: {
      DEFAULT: 'rgb(29 53 87 / 0.1)',
      hover: 'rgb(29 53 87 / 0.2)'
    }
  },
  fonts: {
    heading: 'Days One',
    body: 'Inter'
  },
  gradients: {
    primary: 'from-[#1D3557] to-[#2ECC71]',
    background: 'from-[#1D3557]/5 via-white to-[#1D3557]/5'
  },
  shadows: {
    primary: 'shadow-lg shadow-[#2ECC71]/20'
  },
  spacing: {
    page: {
      x: {
        mobile: '1rem',
        tablet: '1.5rem',
        desktop: '2rem'
      },
      y: {
        mobile: '2rem',
        tablet: '3rem',
        desktop: '4rem'
      }
    }
  },
  borderRadius: {
    DEFAULT: '0.5rem',
    lg: '0.75rem',
    full: '9999px'
  },
  transitions: {
    DEFAULT: 'all 0.3s ease',
    fast: 'all 0.15s ease',
    slow: 'all 0.5s ease'
  }
}; 