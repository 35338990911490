import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { ThemeToggle } from "@/components/ThemeToggle";
import { LogOut, Settings, PenTool, History, User, CreditCard, FolderKanban } from "lucide-react";
import { Suspense, useEffect, useState, useTransition } from "react";
import LandingHeader from "@/components/landing/LandingHeader";
import { theme } from '@/config/theme';

const DEMO_GENERATIONS_LIMIT = 50;

export function Navigation() {
  const { user, signOut, loading: authLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const showNavigation = !location.pathname.startsWith('/auth');
  const isLandingPage = location.pathname === '/';
  const isGeneratorPage = location.pathname === '/generator';
  const [demoGenerationsLeft, setDemoGenerationsLeft] = useState<number | undefined>(undefined);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (!user && isLandingPage) {
      const savedCount = sessionStorage.getItem('demoGenerationsCount');
      const count = savedCount ? parseInt(savedCount) : 0;
      setDemoGenerationsLeft(DEMO_GENERATIONS_LIMIT - count);
    }
  }, [user, isLandingPage]);

  const handleNavigation = (path: string) => {
    if (path === location.pathname) return;
    
    startTransition(() => {
      // Сохраняем состояние текущей страницы перед переходом
      if (location.pathname === '/generator') {
        const event = new Event('beforeunload');
        window.dispatchEvent(event);
      }

      navigate(path, { state: { from: location.pathname } });
    });
  };

  const Logo = () => (
    <div className="flex items-center">
      <span className="font-['Days_One'] text-xl tracking-tight text-[#1D3557]">texty</span>
      <span className="text-[#2ECC71] mx-0.5 font-['Days_One'] text-xl tracking-tight">|</span>
      <span className="font-['Days_One'] text-xl tracking-tight text-[#2ECC71]">me</span>
    </div>
  );

  // Dashboard header
  const DashboardHeader = () => (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center gap-8">
            <Link 
              to="/" 
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              className="hover:opacity-90 transition-opacity"
            >
              <Logo />
            </Link>

            {user && (
              <div className="hidden md:flex items-center gap-4">
                {/* Удаляем кнопку Workspace */}
              </div>
            )}
          </div>

          <div className="flex items-center gap-4">
            {user ? (
              <>
                <Link 
                  to="/subscription"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/subscription');
                  }}
                >
                  <Button 
                    variant={location.pathname.startsWith('/subscription') ? 'default' : 'ghost'}
                    className="gap-2"
                  >
                    <CreditCard className="w-4 h-4" />
                    Subscription
                  </Button>
                </Link>
                <Link 
                  to="/profile"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/profile');
                  }}
                >
                  <Button 
                    variant={location.pathname === '/profile' ? 'default' : 'ghost'}
                    className="gap-2"
                  >
                    <User className="w-4 h-4" />
                    Profile
                  </Button>
                </Link>
              </>
            ) : (
              <Link to="/auth">
                <Button className={`bg-[${theme.colors.primary.DEFAULT}] hover:bg-[${theme.colors.primary.hover}] text-white font-[${theme.fonts.body}] font-bold`}>
                  Get Started
                </Button>
              </Link>
            )}

            <ThemeToggle />

            <Button variant="ghost" size="icon" onClick={signOut} className="text-gray-500">
              <LogOut className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );

  return (
    <div className="min-h-screen flex flex-col">
      {showNavigation && (
        isLandingPage ? (
          <LandingHeader 
            demoGenerationsLeft={demoGenerationsLeft} 
            currentLanguage="English"
          />
        ) : (
          <DashboardHeader />
        )
      )}
      <main className="flex-1 relative">
        <Suspense fallback={
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 backdrop-blur-sm">
            <div className={`animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500 ${isPending || authLoading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}></div>
          </div>
        }>
          <div className="container mx-auto px-4">
            <Outlet />
          </div>
        </Suspense>
      </main>
    </div>
  );
} 