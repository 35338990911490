import { createContext, useContext, useEffect, useState } from "react";
import { AuthError, Session } from "@supabase/supabase-js";
import { AuthUser } from "@/types/auth";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";

type AuthContextType = {
  user: AuthUser | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  // Функция для получения профиля пользователя
  const fetchUserProfile = async (userId: string) => {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        // Если профиль не найден, создаем новый
        if (error.code === 'PGRST116') {
          const { data: newProfile, error: createError } = await supabase
            .from('profiles')
            .upsert({
              id: userId,
              subscription_tier: 'personal',
              subscription_status: 'inactive',
              monthly_generations_count: 0,
              monthly_generations_limit: 500,
              updated_at: new Date().toISOString()
            })
            .select()
            .single();

          if (createError) throw createError;
          return newProfile;
        }
        throw error;
      }

      return profile;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  };

  // Функция для установки пользователя
  const setUserWithProfile = async (session: Session | null) => {
    try {
      if (!session?.user) {
        setUser(null);
        return;
      }

      const profile = await fetchUserProfile(session.user.id);
      setUser({
        id: session.user.id,
        email: session.user.email,
        profile
      });
    } catch (error) {
      console.error('Error setting user:', error);
      setUser(null);
      setError('Ошибка загрузки профиля');
    }
  };

  // Инициализация и подписка на изменения сессии
  useEffect(() => {
    // Получаем текущую сессию
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUserWithProfile(session);
      setLoading(false);
    });

    // Подписываемся на изменения сессии
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUserWithProfile(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError(null);

      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
    } catch (error) {
      const message = error instanceof AuthError ? error.message : "Ошибка входа";
      setError(message);
      toast({
        variant: "destructive",
        title: "Ошибка входа",
        description: message
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError(null);

      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;

      toast({
        title: "Успешно!",
        description: "Пожалуйста, проверьте вашу почту для подтверждения аккаунта",
      });
    } catch (error) {
      const message = error instanceof AuthError ? error.message : "Ошибка регистрации";
      setError(message);
      toast({
        variant: "destructive",
        title: "Ошибка регистрации",
        description: message
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
    } catch (error) {
      const message = error instanceof AuthError ? error.message : "Ошибка выхода";
      setError(message);
      toast({
        variant: "destructive",
        title: "Ошибка выхода",
        description: message
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      loading, 
      error,
      signIn, 
      signUp, 
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
